






















































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';
import InfoDialog from '@/components/app/InfoDialog.vue';

// --- Models ---

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';

// --- Third Party imports ---
import crypto from 'crypto-js';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue';

export interface Phone {
  number: any;
  regionCode: string;
  valid: boolean;
  country: any;
}

@Component({
  components: {
    InfoDialog,
    VueTelInputVuetify,
  },
})
export default class AcceptInvitationPage extends Vue {

  token: string = '';
  username: string = '';

  user = {
    password: '',
    cellphoneCountryRegion: 'ZA',
    cellphoneCountryDialCode: '',
    cellphoneNumber: '',
    idNumber: '',
    idFilename: '',
  };

  uploadFiles: File[] = [];
  tAndC: boolean = false;

  valid: boolean = false;  
  clicked: boolean = false;
  showPassword: boolean = false;
  cellphoneErrorMessage: string = '';

  rules = {
    required: (value: string) => !!value || '',
    min: (v: string) => v.length >= 8 || 'At least 8 characters',
    idNumber: (value: string) => /^(?=.*[a-zA-Z0-9- ])[a-zA-Z0-9- ]+$/.test(value) || 'Invalid character',
    optionalFilesize: (value: File) => value ? (!value || value.size < 1000000 || 'File size should be less than 1 MB') : true,
    optionalPdfFileType: (value: File) => value ? (value.type === 'application/pdf' || 'Invalid file type. Must be a PDF') : true,
  };

  showInfoDialog: boolean = false;
  infoDialogTitle: string = '';
  infoDialogContent: string = '';

  // #region Functions to assist validation rules
    validateCellphoneInput(formattedNumber: any, phone: Phone) {      
      this.cellphoneErrorMessage = phone.valid ? '' : 'Invalid Phone Number';
      if (phone.valid) {
        this.user.cellphoneCountryRegion = phone.regionCode;
        this.user.cellphoneCountryDialCode = phone.country.dialCode;
        this.user.cellphoneNumber = (phone.number.input as string).split(' ').join('');
      }      
    }
  // #endregion

  // region Functions to display data on page load
    async beforeMount() {
      // get our token
      this.token = this.$route.params.token;

      if (this.token && this.token.length > 0) {

        // get our username
        const usernameParam: any = this.$route.query.username;

        if (typeof usernameParam === 'string') {
          this.username = usernameParam as string;

          if (this.username && this.username.length > 0) {
            // load page
          } else {
            this.failVerification();
          }
        } else {
          this.failVerification();
        }
      } else {
        // this should be handled by the router, but just in case
        this.failVerification();
      }
    }

    getCopyRight() {
      return process.env.VUE_APP_COPYRIGHT;
    }

    failVerification() {
      AppService.errorHandlerWithCustomTimeout('Could not complete your eazigear Registration. Please contact Customer Support.', 6000);
      this.$router.push({ name: 'Login'});
    }

    async register() {  
      try {
        this.clicked = true;

        const formData = new FormData();

        formData.append('token', '' + this.token);
        formData.append('username', '' + this.username);
        formData.append('password', '' + crypto.SHA256(this.user.password).toString().toUpperCase());
        formData.append('cellphoneCountryRegion', '' + this.user.cellphoneCountryRegion);
        formData.append('cellphoneCountryDialCode', '' + this.user.cellphoneCountryDialCode);
        formData.append('cellphoneNumber', '' + this.user.cellphoneNumber);
        formData.append('idNumber', '' + this.user.idNumber);
        formData.append('idDocumentFile', this.uploadFiles[0]);

        const response = await AuthService.acceptInvitation( formData );

        if (response && response.data) {
          if (response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandlerWithCustomTimeout(response.data.message, 5000);
            this.goToLogin();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('AcceptInvitation.vue - register - 357 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.clicked = false;
      }
    }

    goToLogin() {
      this.$router.push({ name: 'Login'});
    }
  // #endregion

  // #region Functions required for the Information Dialog
    InformationDialog_cancel() {
      this.showInfoDialog = false;
      this.infoDialogTitle = '';
      this.infoDialogContent = '';
    }

    launchCellphoneInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].cellphone.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].cellphone.content;

      this.showInfoDialog = true;
    }

    launchIDNumberInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].idNumber.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].idNumber.content;

      this.showInfoDialog = true;
    }
  // #endregion
}
